/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from "gatsby";
import styled from "styled-components";
import GlobalStyle from "../utils/GlobalStyles";
// importing custom components
import CustomHeader from "../components/CustomHeader";
import CustomFooter from "../components/CustomFooter";
import Following from "../containers/FollowContainer";
import markImg from "../../static/images/mark.svg";
import Layout from "../components/Layout";

// importing grid system
import { Container, Row, Col } from 'reactstrap';
import SEO from '../seo';


// importing Fonts
import "../../static/fonts/stylesheet.css"

const About = ({ data }) => {

  const Hmax = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    line-height: 1;
    letter-spacing: 0.96px;
    color: #1B1B1B;

    p {
      display: inline;
      margin: 0px !important;
      border: 0px !important;
      padding: 0px !important;
    }

    h6 {
     color: white;
      display: inline-block;
    }

    h5 {
      text-decoration: underline;
      display: inline-block;
    }
    `;

  const Hmax2 = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    line-height: 1;
    letter-spacing: 0.96px;
    color: #1B1B1B;
    margin-top: 75px;
    // width: 340px;

    @media (min-width:1200px) {
      margin-top: 25px;
    }

    @media (max-width: 992px) and (min-width:768px) {
      font-size: 44px;
    }

    @media (max-width: 767px) {
      margin-top: 30px;
    }

    p {
      display: inline;
      margin: 0px !important;
      border: 0px !important;
      padding: 0px !important;
    }

    h6 {
     color: white;
      display: inline-block;
      margin-right:8px;
    }

    h5 {
      text-decoration: underline;
      display: inline-block;
    }
    `;

  const Hmax3 = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    line-height: 1;
    letter-spacing: 0.96px;
    color: #1B1B1B;
    margin-top: 25px;

    @media (max-width:992px) {
      font-size: 44px;
    }

    p {
      display: inline;
      margin: 0px !important;
      border: 0px !important;
      padding: 0px !important;
    }

    h6 {
     color: white;
      display: inline-block;
    }

    h5 {
      text-decoration: underline;
      display: inline-block;
    }
    `;

  const Hmax4 = styled.div`
    font-family: Bebas Neue;
    font-size: 54px;
    line-height: 1;
    letter-spacing: 0.96px;
    color: #1B1B1B;
    margin-top: 10px;

    @media (max-width:992px) {
      font-size: 44px;
    }

    p {
      display: inline;
      margin: 0px !important;
      border: 0px !important;
      padding: 0px !important;
    }

    h6 {
     color: white;
      display: inline-block;
    }

    h5 {
      text-decoration: underline;
      display: inline-block;
    }
    `;

  const Hmin = styled.div`
        font-family: "PT Mono";
        margin-top: 20px;
        line-height: 1.5;
        color: #333333;
        // width: 400px;
        
        @media (max-width:992px) and (min-width:576px){
          width: 340px;
        }

        p{
            margin-top:20px
        }
    `

  const HConMenu = styled.div`
        font-family: Bebas Neue;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1px;
        color: #1B1B1B;
    `

  const HConValue = styled.div`
        font-family: Bebas Neue;
        font-size: 24px;
        line-height: 1;
        letter-spacing: 1.5px;
        color: #1B1B1B;
    `

  const StampImg = styled.img`
        width: 120px;
        height: 120px;
        position: absolute;
        left: -15px;
        top: -35px;

        @media (min-width: 1200px) {
          left: -60px;
          top: 100px;
        }

        @media (max-width:992px) and (min-width: 768px) {
          left: -25px;
          top: 50px;
        }

    `;

  const ImageWrapper = styled.div`
        position: relative;
    `;

  const VisibleTab = styled.div`
    display: none;

    @media(min-width: 768px) {
      display: block;
    }
  `;

  const VisibleMob = styled.div`
    display: none;

    @media(max-width: 767px) {
      display: block;
    }
  `;

  const Row1 = styled(Row)`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 100px;

    @media(min-width: 1200px) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media(max-width: 576px) {
      padding-top: 80px;
      padding-right: 15px;
    }
 `;

  const Row2 = styled(Row)`
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 90px;

    @media(min-width: 1200px) {
      padding-left: 180px;
      padding-right: 180px;
    }

    @media (max-width: 992px) and (min-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media(max-width: 576px) {
      padding-top: 80px;
    }
 `;

  const Col1 = styled(Col)`
    margin-top: 0px;

    @media(max-width: 576px) {
      padding-top: 80px;
    }
  `;

  const Col2 = styled(Col)`
    margin-top: 0px;

    @media (max-width: 992px) {
      padding-top: 30px;
    }
    @media (max-width: 576px) {
      padding-top: 80px;
    }
  `;

  const Background = styled.div`
    height: 350px;

    @media (max-width: 992px) {
      height: 150px;
    }

    @media (max-width: 576px) {
      height:
    }
  `;

  const Image1 = styled.img`
    width: 340px;
    height: 445px;

    @media (min-width: 1200px) {
      width: 430px;
      height: 560px;
    }
  `;

  const Image2 = styled.img`
    width: 340px;
    height: 445px;

    @media (min-width: 1200px) {
      width: 430px;
      height: 560px;
    }
  `;

  const Image3 = styled.img`
    width: 330px;
    height: 445px;

    @media (min-width: 1200px) {
      width: 430px;
      height: 560px;
    }
  `;

  const ColMobInverse = styled(Col)`
    @media (max-width: 767px) {
      order: -1;
    }
  `;

  const AboutSection = styled.section`
    background-color: #F6B5CC;
  `;


  const aboutData = data.allContentfulAboutPage.nodes[0]

  return (
    <Layout>
      <SEO title={aboutData.Title.Title} description={aboutData.metaDescription} />
      <GlobalStyle />
      <CustomHeader />
      <AboutSection>
        <Container style={{ maxWidth: "1250px" }}>
          <Row1 className="justify-content-center">
            <Col1 lg={6} xl={6} md={6} sm={12}>
              <ImageWrapper>
                <Image1 alt={aboutData.firstImage.file.fileName} src={aboutData.firstImage.file.url} />
                <StampImg src={markImg} />
              </ImageWrapper>
            </Col1>
            <ColMobInverse lg={6} xl={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Hmax2 dangerouslySetInnerHTML={{ __html: aboutData.firstTitle.childContentfulRichText.html }} />
              <Hmin dangerouslySetInnerHTML={{ __html: aboutData.FirstDescription.childContentfulRichText.html }} />
            </ColMobInverse>
          </Row1>
          <Row1 className="justify-content-center">
            <Col lg={5} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Hmax3 dangerouslySetInnerHTML={{ __html: aboutData.secondTitle.childContentfulRichText.html }} />
              <Hmin dangerouslySetInnerHTML={{ __html: aboutData.secondDescription.childContentfulRichText.html }} />
            </Col>
            <Col2 className="offset-lg-1" lg={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Image2 alt={aboutData.secondImage.file.fileName} src={aboutData.secondImage.file.url} />
            </Col2>
          </Row1>
          <Row1 className="justify-content-center">
            <VisibleMob>
              <Col lg={6} xl={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <Hmax2 style={{ fontSize: 44 }} dangerouslySetInnerHTML={{ __html: aboutData.thirdTitle.childContentfulRichText.html }} />
                <Hmax2 style={{ fontSize: 44, marginTop: 10 }} dangerouslySetInnerHTML={{ __html: aboutData.thirdDescription.childContentfulRichText.html }} />
              </Col>
            </VisibleMob>
            <Col1 lg={6} xl={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <Image3 alt={aboutData.thirdImage.file.fileName} src={aboutData.thirdImage.file.url} />
            </Col1>

            <Col lg={6} xl={6} md={6} sm={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <VisibleTab>
                <Hmax3 dangerouslySetInnerHTML={{ __html: aboutData.thirdTitle.childContentfulRichText.html }} />
                <Hmax4 dangerouslySetInnerHTML={{ __html: aboutData.thirdDescription.childContentfulRichText.html }} />
              </VisibleTab>
            </Col>
          </Row1>
          <Row2 className="justify-content-center">
            <Col lg={4} xl={4} md={6} sm={12}>
              <HConMenu>Call Us</HConMenu>
              <HConValue style={{ minWidth: 340, marginBottom: 50 }}>{aboutData.phoneNumber}</HConValue>
            </Col>
            <Col lg={4} xl={4} md={6} sm={12}>
              <HConMenu>Customer Support</HConMenu>
              <HConValue style={{ minWidth: 340, marginBottom: 50 }}>{aboutData.email}</HConValue>
            </Col>
            <Col lg={4} xl={4} md={12} sm={12}>
              <HConMenu>Location</HConMenu>
              <HConValue style={{ minWidth: 340, marginBottom: 50 }}>{aboutData.location}</HConValue>
            </Col>
          </Row2>
        </Container>
      </AboutSection>
      <Background style={{
        backgroundImage: `url('/images/aboutBg.svg')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%'
      }} />
      <Following />
      <CustomFooter />
    </Layout >
  )
};

export const query = graphql`
{
  allContentfulAboutPage {
    nodes {
      id
      Title {
        Title
      }
      metaDescription
      firstTitle {
        childContentfulRichText {
          html
        }
      }
      FirstDescription {
        childContentfulRichText {
          html
        }
      }
      firstImage {
        file {
          url
          fileName
        }
      }
      secondTitle {
        childContentfulRichText {
          html
        }
      }
      secondDescription {
        childContentfulRichText {
          html
        }
      }
      secondImage {
        file {
          url
          fileName
        }
      }
      thirdTitle {
        childContentfulRichText {
          html
        }
      }
      thirdDescription {
        childContentfulRichText {
          html
        }
      }
      thirdImage {
        file {
          url
          fileName
        }
      }
      phoneNumber
      email
      location
    }
  }
}
`


export default About;
